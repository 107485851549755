import React from "react";
import styled from 'styled-components';
import Form from 'react-bootstrap/Form'

const FormWrapper = styled.div`
  
form {
    max-width: 500px;

  }
  
  h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
  }
  
  .form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
  }
  
  p {
    color: #bf1650;
    width:100%;
    text-align:left;
    margin-left:5px;
    font-size:12px;
  }
  
  

input[type=text] ,input[type=number], input[type=password], input[type=email]{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom:10px;
}
 input[type=checkbox], input[type=radio]{
  line-height: 2;
  text-align: left;
  display: flex;
  margin-bottom:5px;
  margin-top:5px;
  color: black;
  font-size: 14px;
  font-weight: 200;
 }

  
  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom:5px;
    margin-top:5px;
    font-size: 14px;
    font-weight: 200;
  }
  
  button[type="submit"],
  input[type="submit"] {
    background: #0481b5 ;
    color: white;
    text-transform: uppercase;
    border: none;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 4px !important;
    // width: 125px !important;
    font-size: 16px;
    border-radius: .25rem;
    font-weight: 100;
    display: flex;
justify-content: center;
  }
  
  button[type="submit"]:hover,
  input[type="submit"]:hover {
    background: #136486;
  }
  
  button[type="submit"]:active,
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  button[type="submit"]:focus,
  input[type="button"]:focus,
  input[type="submit"]:focus {

    outline:none;
  }
  
  input[type="text"]:focus ,input[type="number"]:focus, input[type="email"]:focus{
    outline: none;
    border: 1px solid dodgerblue;
}
 textarea:focus{
   outline:none;
  border: 1px solid dodgerblue;
}
  input[type="button"]:hover {
    transition: 0.3s all;
  }
  
  button[type="submit"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
  
`;

const Forms = (props) => {
  return (
    <FormWrapper {...props}>
      <Form style={props.formStyle} className={props.formClass} onSubmit={props.handleSubmit(props.onSubmit)} >
        {props.children}
        <input type="submit" value={props.buttonvalue ? props.buttonvalue : "Submit"} style={{ backgroundColor: '#00aae4' }} />
      </Form>
    </FormWrapper>

  );
}
export default Forms