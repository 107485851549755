/*
* Affordability Calculator Page
*/

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styles from './index.module.css';

const Iframewrapper = styled.div`


`


const key = 'home';

export function AffordabilityCalculators({

}) {

  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <article>
      <Helmet>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

        <title>Affordability Calculator | LowRates</title>
      </Helmet>
      <Iframewrapper>
        <div>
          <img
            src={imgCDNURL + "loanprogrambannerimage.webp"}
            className={styles.loanprogrambannerimage}

          />

          <img
            src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
            className={styles.loanprogrambannerimagemobile}

          />
          <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
            <img
              src={imgCDNURL + "applynowbanner.webp"}
              className={styles.applynowbanner}
            />
          </a>
          <div className={styles.reficalculatorStyleDiv}>
            <Container fluid className={styles.pageContainer}>
              <Row className={styles.header}>
                <h1>Affordability Calculator</h1>
                <h2>Simple, Fast Service.</h2>

              </Row>
              {/* An iframe is implemented to show the calculator from another html page */}
              <div className={styles.iFrameDiv}>

                <iframe src="https://prod-cdn.swmc.com/mortgage-calculators/Swmc-calculators/affordability-calculator.html" height="1380px" overflow="hidden" className={styles.iFrame}></iframe>
              </div>




            </Container>
          </div>
        </div>
      </Iframewrapper>
    </article>
  );
}

export default AffordabilityCalculators
