/*
* Rent Calculator Page
*/

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styles from './index.module.css';
const key = 'rentCalculator';

export function RentVsBuyCalculator({

  location
}) {
  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);



  return (
    <article>
      <Helmet>

        <title>Rent Vs. Buy Calculator | LowRates</title>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

      </Helmet>
      <div>
        <img
          src={imgCDNURL + "loanprogrambannerimage.webp"}
          className={styles.loanprogrambannerimage}

        />

        <img
          src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
          className={styles.loanprogrambannerimagemobile}

        />
        <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
          <img
            src={imgCDNURL + "applynowbanner.webp"}
            className={styles.applynowbanner}
          />
        </a>
        <div className={styles.reficalculatorStyleDiv}>
          <Container fluid className={styles.pageContainer}>
            <Row className={styles.header}>
              <h1>Rent Vs. Buy Calculator</h1>
              <h2>Simple, Fast Service.</h2>
            </Row>
            <div className={styles.iFrameDiv}>
              <iframe src="https://prod-cdn.swmc.com/mortgage-calculators/Swmc-calculators/rent-vs-buy-calculator.html" height="1944px" className={styles.iFrame}></iframe>
            </div>
          </Container>
        </div>
      </div>
    </article>
  );
}


export default RentVsBuyCalculator
